import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../helpers/apiHelper';
import LikertChart from '../components/LikertChart';
import OpenTextResponses from '../components/OpenTextResponses';
import CriticalFeedback from '../components/CriticalFeedback';
import moment from 'moment'; // Ensure moment is installed

function SurveyResults() {
    const { surveyId } = useParams();
    const [allData, setAllData] = useState([]); // Store all fetched data
    const [chartData, setChartData] = useState(null);
    const [openTextResponses, setOpenTextResponses] = useState([]);
    const [criticalFeedback, setCriticalFeedback] = useState([]);
    const [totalResponses, setTotalResponses] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState('all'); // Filtering based on time

    useEffect(() => {
        fetchSurveyData();
    }, [surveyId]);

    useEffect(() => {
        if (allData.length) {
            applyFilter();
        }
    }, [filter]);

    const fetchSurveyData = async () => {
        setLoading(true);
        try {
            const data = await get(`survey-responses/${surveyId}`);
            setAllData(data);
            applyFilter(data); // Apply initial filter when data is fetched
        } catch (err) {
            console.error('Error fetching survey data:', err);
            setError('Failed to load survey data');
            setLoading(false);
        }
    };

    const applyFilter = (data = allData) => {
        const filteredData = data.filter(d => {
            const createdAt = moment(d.createdAt);
            switch(filter) {
                case 'today':
                    return createdAt.isSame(moment(), 'day');
                case 'week':
                    return createdAt.isSame(moment(), 'week');
                case 'month':
                    return createdAt.isSame(moment(), 'month');
                case 'year':
                    return createdAt.isSame(moment(), 'year');
                default:
                    return true; // all data
            }
        });
        processAndSetData(filteredData);
    };

    const processAndSetData = (data) => {
        const processedData = processDataForChart(data);
        setChartData(processedData.chartData);
        setOpenTextResponses(processedData.openTextResponses);
        setCriticalFeedback(processedData.criticalFeedback);
        setTotalResponses(data.length);
        setLoading(false);
    };

    const processDataForChart = (data) => {
        const answerSums = {};
        const answerCounts = {};
        const openTexts = [];
        const criticalFeedback = [];

        data.forEach(response => {
            response.survey.questions.forEach((question, index) => {
                const answer = parseInt(response.answers[index], 10);
                if (question.type === "likert") {
                    answerSums[index] = (answerSums[index] || 0) + answer;
                    answerCounts[index] = (answerCounts[index] || 0) + 1;
                    if (answer <= 2) {
                        criticalFeedback.push({
                            name: response.name || 'Anónimo',
                            email: response.email || 'N/A',
                            phone: response.phone || 'N/A',
                            question: question.question,
                            answer: answer,
                            date: response.createdAt
                        });
                    }
                } else if (question.type === "openEnded") {
                    openTexts.push({ question: question.question, answer: response.answers[index] });
                }
            });
        });

        const labels = Object.keys(answerSums).map(index => data[0].survey.questions[index].question);
        const datasets = [{
            label: 'Calificación Media',
            data: Object.keys(answerSums).map(index => answerSums[index] / answerCounts[index]),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }];

        return {
            chartData: { labels, datasets },
            openTextResponses: openTexts,
            criticalFeedback: criticalFeedback,
            totalResponses: data.length
        };
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-6">
            <h1 className="text-xl md:text-2xl font-bold text-center mb-4">Survey Results</h1>
            <div className="flex flex-wrap justify-center gap-2 my-4">
                <button onClick={() => setFilter('today')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-xs md:text-sm">
                    Hoy
                </button>
                <button onClick={() => setFilter('week')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-xs md:text-sm">
                    Esta Semana
                </button>
                <button onClick={() => setFilter('month')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-xs md:text-sm">
                    Este Mes
                </button>
                <button onClick={() => setFilter('year')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-xs md:text-sm">
                    Este Año
                </button>
                <button onClick={() => setFilter('all')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out text-xs md:text-sm">
                    Todos
                </button>
            </div>
            <div className="text-lg bg-gray-100 p-3 rounded-lg shadow text-center mb-4">Total Responses: {totalResponses}</div>
            <LikertChart data={chartData} />
            <OpenTextResponses responses={openTextResponses} />
            <CriticalFeedback feedback={criticalFeedback} />
        </div>
    );
}

export default SurveyResults;
