import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import SurveyCreator from './pages/SurveyCreator';
import SurveyPage from './pages/SurveyPage';
import SurveyResults from './pages/SurveyResults';
import PublicRoute from './components/PublicRoute';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePolicyPage from './pages/CookiePolicyPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentResult from './pages/PaymentResult';
import Settings from './pages/Settings';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import EmailVerification from './pages/EmailVerification';

const stripePub = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(stripePub);

function App() {
  return (
    <Router>
      <Header />
      <main className="flex-grow">
        <Elements stripe={stripePromise}>
          <Routes>
            {/* Envoltura para rutas publicas */}
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword/>} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/verify" element={<EmailVerification />} />
            </Route>

            {/* Envoltura para rutas protegidas */}
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/create-survey" element={<SurveyCreator />} />
              <Route path="/survey-results/:surveyId" element={<SurveyResults />} />
              <Route path="/settings" element={<Settings/>} />
              {/* Otros rutas protegidas */}
            </Route>

            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/surveys/:surveyId" element={<SurveyPage />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />        
            {/* Redirige cualquier ruta desconocida al inicio */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Elements>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
