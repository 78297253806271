// Importar useState, useEffect, y differenceInCalendarDays desde react
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { differenceInCalendarDays } from 'date-fns'; // Usamos date-fns para manejar fechas
import useAuthStore from '../store/authStore';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { get } from '../helpers/apiHelper';

function Header() {
  const { user, setUser, logout } = useAuthStore();
  const navigate = useNavigate();
  const [daysLeft, setDaysLeft] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user?.userId && !user.hasPaid) {
        try {
          const userDetails = await get(`users/${user.userId}`);
          setUser({ ...userDetails, token: user.token });
          const trialEnd = new Date(userDetails.trialStarted);
          trialEnd.setDate(trialEnd.getDate() + 7);
          const today = new Date();
          const days = differenceInCalendarDays(trialEnd, today);
          setDaysLeft(days > 0 ? days : 0);

          // Si el período de prueba ha finalizado, mostrar el modal directamente
          if (days <= 0) {
            setShowModal(true);
          }
        } catch (error) {
          console.error('Error al obtener los detalles del usuario:', error);
        }
      }
    };
  
    fetchUserDetails();
  }, [user?.userId, setUser]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handlePayment = async () => {
    Swal.fire({
      title: 'Complete su pago',
      text: 'Haga clic a continuación para completar su pago.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ir al pago',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Llamada a la API para iniciar el pago utilizando el helper 'get'
          const response = await get(`payments/start-payment/${user.userId}`);
          // Redirección a Stripe
          window.location.href = response.paymentUrl;
        } catch (error) {
          console.error('Error al iniciar el pago:', error);
          Swal.fire('Error', 'Error al iniciar el pago. Inténtelo de nuevo.', 'error');
        }
      }
    });
  };

  // Mostrar el modal si el período de prueba ha finalizado
  useEffect(() => {
    if (showModal) {
      Swal.fire({
        title: 'Período de prueba finalizado',
        text: 'Su período de prueba ha finalizado. Haga clic a continuación para completar su pago.',
        icon: 'warning',
        showCancelButton: false, // No mostrar botón de cancelar
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ir al pago',
        allowOutsideClick: false, // Evitar cerrar haciendo clic fuera del modal
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Llamada a la API para iniciar el pago utilizando el helper 'get'
            const response = await get(`payments/start-payment/${user.userId}`);
            // Redirección a Stripe
            window.location.href = response.paymentUrl;
          } catch (error) {
            console.error('Error al iniciar el pago:', error);
            Swal.fire('Error', 'Error al iniciar el pago. Inténtelo de nuevo.', 'error');
          }
        }
      });
    }
  }, [showModal]);

  return (
    <header className="bg-blue-800 text-white p-4 flex justify-between items-center">
      <h1 className="text-2xl font-bold cursor-pointer">
        <Link to="/" className="hover:underline">PetitFeedback</Link>
      </h1>
      {user ? (
        <div className="flex items-center space-x-4">
          {!user.hasPaid && daysLeft > 0 && (
            <div onClick={handlePayment} className="cursor-pointer bg-yellow-500 hover:bg-yellow-700 rounded-full px-4 py-2">
              {daysLeft} días restantes en el período de prueba
            </div>
          )}
          {!user.hasPaid && daysLeft <= 0 && (
            <div onClick={handlePayment} className="cursor-pointer bg-red-500 hover:bg-red-700 rounded-full px-4 py-2">
              Período de prueba finalizado - Haga clic para completar su pago
            </div>
          )}
          
          <Link to="/settings" className="py-2 px-4 bg-blue-600 hover:bg-blue-800 rounded-full text-lg transition-colors duration-300">
            <FontAwesomeIcon icon={faCog} className="text-xl" />
          </Link>
          <button onClick={handleLogout} className="py-2 px-4 bg-red-600 hover:bg-red-800 rounded-full text-lg transition-colors duration-300">
            <FontAwesomeIcon icon={faSignOutAlt} className="text-xl" />
          </button>
        </div>
      ) : (
        <button onClick={() => navigate('/login')} className="py-2 px-4 bg-green-600 hover:bg-green-800 rounded-full text-lg transition-colors duration-300">
          Iniciar sesión
        </button>
      )}
    </header>
  );
}

export default Header;
