import React, { useState } from 'react';
import useAuthStore from '../store/authStore';
import { post } from '../helpers/apiHelper';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser, logout } = useAuthStore(state => ({
    setUser: state.setUser,
    logout: state.logout
  }));
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await post('auth/login', { email, password });
      if (response.token) {
        setUser({ userId: response.userId, token: response.token });
        Swal.fire('Success', 'Login succesful!', 'success');
        navigate('/dashboard');
      } else {
        throw new Error('Invalid login response');
      }
    } catch (error) {
      console.error('Login failed:', error);
      Swal.fire('Error', 'Login failed: ' + error.message, 'error');
      logout();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-500 via-blue-500 to-blue-400 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold">
            Inicia sesión en tu cuenta
          </h2>
          <p className="mt-2 text-center text-sm">
            ¿No tienes cuenta? <a href="/register" className="font-medium text-black hover:text-indigo-100">
              Regístrate
            </a>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">Correo electrónico</label>
              <input id="email-address" name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-transparent placeholder-black text-gray-900 rounded-t-md focus:outline-none focus:ring-white focus:border-white focus:z-10 sm:text-sm" placeholder="Introduce tu correo" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Contraseña</label>
              <input id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-transparent placeholder-black text-gray-900 rounded-b-md focus:outline-none focus:ring-white focus:border-white focus:z-10 sm:text-sm" placeholder="Introduce tu contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
          <div>
          <div className="text-sm text-center mt-2">
            <Link to="/reset-password" className="font-medium text-black hover:text-indigo-100">
                ¿Olvidaste tu contraseña?
            </Link>
          </div>
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
