import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link de react-router-dom

function Footer() {
  return (
    <footer className="bg-blue-800 text-white p-4 text-center">
      <div className="flex justify-center space-x-4 mb-2">
        <Link to="/terminos" className="hover:underline">Términos y Condiciones</Link>
        <Link to="/politica-de-cookies" className="hover:underline">Política de Cookies</Link>
      </div>
      <p>Copyright © {new Date().getFullYear()} PetitFeedback. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
