import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const prices = [
    { country: "España", price: "15.00€" },
    { country: "Perú", price: "40.00 PEN" },
    { country: "Chile", price: "7500 CLP" },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-indigo-500 via-blue-500 to-blue-400 text-white px-4">
      <header className="text-center space-y-6 mt-12"> {/* Espacio adicional entre el header y el contenido */}
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold">
          Simplifica la Recopilación de Feedback con PetitFeedback
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl">
          PetitFeedback es tu aliado estratégico en encuestas, diseñado para pequeños y medianos comercios.
        </p>
        <p className="text-lg md:text-xl lg:text-2xl">
          Nuestra herramienta te permite entender mejor a tus clientes a través de encuestas intuitivas y efectivas, implementando un enfoque de marketing basado en datos sin complicaciones. ¡Invierte una sola vez y disfruta de todos los beneficios de por vida! Además, ofrecemos un <strong>free trial de 7 días</strong> para que puedas experimentar todos los beneficios antes de decidir.
        </p>

        <div className="flex justify-center space-x-4">
          <Link to="/login" className="bg-green-600 hover:bg-green-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Iniciar Sesión
          </Link>
          <Link to="/register" className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out">
            Registrarse
          </Link>
        </div>
      </header>

      <div className="animate-bounce mt-20">
        <img src="/logo.png" alt="PetitFeedback logo" />
      </div>

      <section className="w-full mb-10">
        <div className="max-w-2xl mx-auto mt-10">
          <h2 className="text-2xl text-center text-white font-bold mb-6">Precios Únicos por País</h2>
          <p className="text-center text-white mb-6">Un solo pago para acceder a una herramienta poderosa que te permite recoger feedback de clientes en tiempo real, ayudándote a mejorar continuamente la calidad de tu servicio.</p>
          <div className="overflow-hidden rounded-lg shadow-lg">
            <table className="w-full text-sm text-left text-white">
              <thead className="text-xs text-white uppercase bg-blue-700 rounded-t-lg">
                <tr>
                  <th scope="col" className="px-6 py-3">País</th>
                  <th scope="col" className="px-6 py-3">Precio</th>
                </tr>
              </thead>
              <tbody>
                {prices.map((item, index) => (
                  <tr key={index} className="bg-blue-500 last:border-b-0 hover:bg-blue-600">
                    <td className="px-6 py-4">{item.country}</td>
                    <td className="px-6 py-4">{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-8 text-lg md:text-xl lg:text-2xl text-center">
          <p>Realizar encuestas de satisfacción ayuda a retener clientes y mejorar continuamente la calidad del servicio, asegurando que las expectativas de los consumidores se cumplan de manera eficaz y eficiente.</p>
        </div>
      </section>
    </div>
  );
}

export default Home;
