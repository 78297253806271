import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import { get, post } from '../helpers/apiHelper';
import SurveyCreator from './SurveyCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faFilePdf, faLink, faPoll, faPowerOff, faPlay } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import QRCode from 'qrcode';
import { jsPDF } from "jspdf";
import { differenceInCalendarDays } from 'date-fns';

function Dashboard() {
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, userId, token, isAuthenticated, setUser } = useAuthStore();
    const [daysLeft, setDaysLeft] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isAuthenticated && user && user.userId && token) {
            fetchSurveys();
            fetchUserDetails();
        }
    }, [token, user, isAuthenticated]);

    const fetchSurveys = async () => {
        setLoading(true);
        try {
            const data = await get(`surveys/my-surveys/${user.userId}`);
            setSurveys(data);
        } catch (error) {
            console.error('Failed to fetch surveys:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserDetails = async () => {
        if (user?.userId && !user.hasPaid) {
            try {
                const userDetails = await get(`users/${user.userId}`);
                setUser({ ...userDetails, token: user.token });
                const trialEnd = new Date(userDetails.trialStarted);
                trialEnd.setDate(trialEnd.getDate() + 7);
                const today = new Date();
                const days = differenceInCalendarDays(trialEnd, today);
                setDaysLeft(days > 0 ? days : 0);

                if (days <= 0) {
                    setShowModal(true);
                }
            } catch (error) {
                console.error('Error al obtener los detalles del usuario:', error);
            }
        }
    };

    const showPaymentModal = () => {
        Swal.fire({
            title: 'Período de prueba finalizado',
            text: 'Su período de prueba ha finalizado. Haga clic a continuación para completar su pago.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ir al pago',
            allowOutsideClick: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await get(`payments/start-payment/${user.userId}`);
                    window.location.href = response.paymentUrl;
                } catch (error) {
                    console.error('Error al iniciar el pago:', error);
                    Swal.fire('Error', 'Error al iniciar el pago. Inténtelo de nuevo.', 'error');
                }
            }
        });
    };

    const handleAction = (action) => {
        if (daysLeft <= 0 && !user.hasPaid) {
            showPaymentModal();
        } else {
            action();
        }
    };

    const showSurveyLink = (surveyId) => {
        const surveyUrl = `${process.env.REACT_APP_BASE_FE_URL}/surveys/${surveyId}`;
        Swal.fire({
            title: 'Enlace de la Encuesta',
            html: `
                <input type="text" value="${surveyUrl}" class="swal2-input" id="surveyLink" readonly>
                <button id="copyButton" class="swal2-confirm swal2-styled">Copiar enlace</button>
            `,
            icon: 'info',
            showConfirmButton: false,
            didOpen: () => {
                const copyButton = document.getElementById('copyButton');
                copyButton.addEventListener('click', () => {
                    const linkInput = document.getElementById('surveyLink');
                    linkInput.select();
                    navigator.clipboard.writeText(surveyUrl).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Copiado!',
                            timer: 1500,
                            showConfirmButton: false
                        });
                    }).catch(err => {
                        console.error('Error al copiar el enlace:', err);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al copiar',
                            text: 'No se pudo copiar el enlace al portapapeles.',
                            confirmButtonColor: '#dc3545'
                        });
                    });
                });
            }
        });
    };

    const showQRCode = (surveyId) => {
        const surveyUrl = `${process.env.REACT_APP_BASE_FE_URL}/surveys/${surveyId}`;
        QRCode.toDataURL(surveyUrl)
            .then(url => {
                Swal.fire({
                    title: 'Código QR de la Encuesta',
                    html: `
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <img src="${url}" alt="QR Code" style="margin-top: 20px;"/>
                    </div>
                    `,
                    confirmButtonText: 'Cerrar',
                    width: 400,
                    padding: '3em',
                    background: '#fff',
                    customClass: {
                        popup: 'custom-swal'
                    }
                });
            })
            .catch(err => {
                console.error('Error generating QR code', err);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al generar el código QR',
                    text: 'No se pudo generar el código QR.',
                    confirmButtonColor: '#dc3545'
                });
            });
    };

    const downloadPDFWithQR = (surveyId) => {
        const surveyUrl = `${process.env.REACT_APP_BASE_FE_URL}/surveys/${surveyId}`;
        QRCode.toDataURL(surveyUrl)
            .then(url => {
                const pdf = new jsPDF();

                pdf.setTextColor(60, 77, 113);
                pdf.setFont("helvetica", "bold");

                pdf.setFontSize(22);
                pdf.text("¡Tu Opinión Cuenta!", 105, 40, null, null, 'center');

                pdf.setFont("helvetica", "normal");
                pdf.setFontSize(14);
                pdf.text("Nos importa mucho tu experiencia y queremos mejorarla.", 105, 60, null, null, 'center');
                pdf.text("Por favor, dedica unos momentos para responder nuestra encuesta.", 105, 75, null, null, 'center');

                pdf.setFontSize(16);
                pdf.text("Escanea el código QR para empezar:", 105, 95, null, null, 'center');

                const imgProps = pdf.getImageProperties(url);
                const imgHeight = (imgProps.height * 80) / imgProps.width;
                pdf.addImage(url, 'PNG', 70, 105, 80, imgHeight);

                pdf.setTextColor(150);
                pdf.setFontSize(12);
                pdf.text("Si necesitas ayuda, contacta con alguno de nuestros trabajadores.", 105, 250, null, null, 'center');
                pdf.text("Tu opinion nos ayuda a mejorar", 105, 265, null, null, 'center');

                pdf.save(`Survey-${surveyId}.pdf`);
            })
            .catch(err => {
                console.error('Error generating QR code', err);
                alert('Error al generar el QR: ' + err);
            });
    };

    const goToResults = (surveyId) => {
        navigate(`/survey-results/${surveyId}`);
    };

    const toggleSurveyActive = async (surveyId) => {
        try {
            const updatedSurvey = await post(`surveys/toggle-active/${surveyId}`);
            setSurveys(surveys.map(survey => survey.id === surveyId ? updatedSurvey : survey));
            Swal.fire('Éxito', 'El estado de la encuesta ha sido actualizado.', 'success');
        } catch (error) {
            console.error('Error toggling survey active status:', error);
            Swal.fire('Error', 'No se pudo cambiar el estado de la encuesta.', 'error');
        }
    };

    return (
        <div className="min-h-screen text-black p-10">
            <div className="mt-auto mb-auto">
                <h1 className="text-4xl font-bold mb-4">Tu Dashboard</h1>
                <p className="text-xl mb-4">¡Bienvenido! Aquí puedes gestionar tus encuestas.</p>
                <SurveyCreator />
                {loading ? (
                    <div className="flex justify-center items-center min-h-screen">
                        <p className="text-lg text-gray-600">Cargando encuestas...</p>
                    </div>
                ) : (
                    surveys.length > 0 ? (
                        <div className="flex flex-col items-center justify-center w-full py-10">
                            <div className="w-full max-w-2xl px-4">
                                {surveys.map((survey) => (
                                    <div key={survey.id} className="mb-4 p-4 bg-white rounded-lg shadow-lg text-gray-800">
                                        <h3 className="text-center text-xl font-semibold">{survey.title}</h3>
                                        <div className="flex justify-center space-x-4 mt-4">
                                            <button
                                                onClick={() => handleAction(() => showQRCode(survey.id))}
                                                className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                                title="Mostrar código QR"
                                            >
                                                <FontAwesomeIcon icon={faQrcode} className="text-xl" />
                                            </button>
                                            <button
                                                onClick={() => handleAction(() => downloadPDFWithQR(survey.id))}
                                                className="text-red-500 hover:text-red-700 focus:outline-none"
                                                title="Descargar PDF con código QR"
                                            >
                                                <FontAwesomeIcon icon={faFilePdf} className="text-xl" />
                                            </button>
                                            <button
                                                onClick={() => handleAction(() => showSurveyLink(survey.id))}
                                                className="text-green-500 hover:text-green-700 focus:outline-none"
                                                title="Mostrar enlace de la encuesta"
                                            >
                                                <FontAwesomeIcon icon={faLink} className="text-xl" />
                                            </button>
                                            <button
                                                onClick={() => handleAction(() => goToResults(survey.id))}
                                                className="text-yellow-500 hover:text-yellow-700 focus:outline-none"
                                                title="Ver resultados"
                                            >
                                                <FontAwesomeIcon icon={faPoll} className="text-xl" />
                                            </button>
                                            <button
                                                onClick={() => handleAction(() => toggleSurveyActive(survey.id))}
                                                className="text-purple-500 hover:text-purple-700 focus:outline-none"
                                                title={survey.isActive ? "Desactivar encuesta" : "Activar encuesta"}
                                            >
                                                <FontAwesomeIcon icon={survey.isActive ? faPowerOff : faPlay} className="text-xl" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center min-h-screen">
                            <p className="text-lg text-gray-600">No se encontraron encuestas.</p>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default Dashboard;
